import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Register from "./Register";
import Login from "./Login";
import RequestPasswordReset from "./RequestPasswordReset";
import ResetPassword from "./ResetPassword";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 900,
      lg: 1200,
      xl: 1536,
      customSmall: 350, // Custom breakpoint
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 20px 20px 20px",
  textAlign: "center",
  zIndex: 1300,
  width: "calc(100% - 80px)",
  maxWidth: "500px",
  borderRadius: "10px",
};

const WelcomeModal = ({
  open,
  handleClose,
  onLoginSuccess,
  view,
  setView,
  verificationMessage,
}) => {
  const renderContent = () => {
    switch (view) {
      case "register":
        return (
          <div>
            <Register setView={setView} />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setView("welcome")}
              style={{ marginTop: "10px" }}
            >
              Zpět
            </Button>
          </div>
        );
      case "login":
        return (
          <div>
            {verificationMessage && (
              <Typography variant="h6" component="h2">
                {verificationMessage}
              </Typography>
            )}
            <Login onLoginSuccess={onLoginSuccess} setView={setView} />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setView("welcome")}
              style={{ marginTop: "10px" }}
            >
              Zpět
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setView("register")}
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Registrovat
            </Button>
          </div>
        );
      case "requestPasswordReset":
        return (
          <div>
            <RequestPasswordReset setView={setView} />
          </div>
        );
      case "resetPassword":
        return (
          <div>
            <ResetPassword />
        {/*     <Button
              variant="outlined"
              color="primary"
              onClick={() => setView("login")}
              style={{ marginTop: "10px" }}
            >
              Zpět na přihlášení
            </Button> */}
          </div>
        );
      case "verification":
        return (
          <div>
            <Typography variant="h6" component="h2">
              {verificationMessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setView("login")}
            >
              Jít na přihlášení
            </Button>
          </div>
        );
      default:
        return (
          <>
            <img
              src="/logo.png"
              alt="Logo"
              style={{ width: "100%", p: 200, borderRadius: "10px" }}
            />
            <Typography
              id="modal-welcome-title"
              variant="h6"
              component="h2"
              padding={2}
            >
            
Teď už nezapomeneš zdolat žádný vrchol krásného Českého středohoří! 
            </Typography>
            <Box mt={2} padding={"0px 0px 0px 0px"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setView("register")}
                sx={{
                  mt: { xs: 1, sm: 0.5 },
                  mx: { sm: 0.5 },
                }}
              >
                Registrace
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setView("login")}
                sx={{
                  ml: { xs: 0 }, // Responsive margin-left
                  mt: { xs: 1, sm: 0.5 },
                  mx: { sm: 0.5 }, // Responsive padding-top
                }}
              >
                Přihlášení
              </Button>
            </Box>
          </>
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-welcome-title"
        aria-describedby="modal-welcome-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>{renderContent()}</Box>
      </Modal>
    </ThemeProvider>
  );
};

export default WelcomeModal;
