import React from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StatsDrawer = ({ open, onClose, loading, data }) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: { width: "100%" }, // Set drawer width to 100%
    }}
  >
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      p: 2,
      zIndex: 2, // Ensures the top box is above other elements
      backgroundColor: "white",
      // Optional: Add shadow for separation
      minHeight: "50px", // Ensure the box has enough height even on small devices
    }}
  >
    <Typography variant="h6">Mé kopce</Typography>
    <IconButton
      edge="end"
      color="inherit"
      onClick={onClose}
      sx={{
        zIndex: 2, // Ensures the icon is above other elements
        position: "relative", // Keeps it inside the layout flow
        pr: 1, // Adjust padding
      }}
    >
      <CloseIcon />
    </IconButton>
  </Box>

    {loading ? (
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <CircularProgress />
      </Box>
    ) : (
      <TableContainer component={Paper} sx={{ p: "10px 0 0 0", width: "100%" }}>
        <Table size="normal" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
              <TableCell sx={{ fontWeight: "bold", width: "5%", px: 1 }}>
                #
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "25%", px: 1 }}>
                Jméno
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "25%", px: 1 }}
                align="center"
              >
                Výška (m)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "25%", px: 1 }}
                align="center"
              >
                Prominence (m)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "20%", px: 1 }}
                align="center"
              >
                Zdoláno dne
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((hill, index) => (
              <TableRow key={hill._id}>
                <TableCell sx={{ wordWrap: "break-word", px: 1 }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ wordWrap: "break-word", px: 1 }}>
                  {hill.hillId.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ wordWrap: "break-word", px: 1 }}
                >
                  {hill.hillId.height}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ wordWrap: "break-word", px: 1 }}
                >
                  {hill.hillId.prominence}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ wordWrap: "break-word", px: 1 }}
                >
                  {new Date(hill.climbDate).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </Drawer>
);

export default StatsDrawer;
