import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,

  useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import VerifyEmail from "./components/VerifyEmail";

import WelcomeModal from "./components/WelcomeModal";
import BurgerMenu from "./components/BurgerMenu";
import MapView from "./components/mapComponents/MapView";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import "./App.css"; // Ensure your CSS file includes the fade-out and fade-in styles
import axios from "axios";

const theme = createTheme();

function AppContent() {
  const { user, logout, loading } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [modalView, setModalView] = useState("welcome");
  const [userData, setUserData] = useState(null);
  const [modalFadeClass, setModalFadeClass] = useState("fade-in"); // State for modal fade-in/fade-out
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      if (user) {
        console.log("User is logged in, fetching user data...");
        fetchUserData();
        setOpen(false); // Close modal once the user is logged in
      } else {
        console.log("No user logged in, showing modal...");
        setOpen(true); // Show modal if no user is logged in
      }
    }
  }, [user, loading]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
  
    if (token) {
      setModalView("resetPassword");
      setOpen(true); // Ensure the modal is open when the user tries to reset password
    }
  }, [location]); // Runs whenever the URL changes
  

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/user/climbedHills`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user's climbed hills:", error);
      setOpen(true); // Optionally display modal in case of an error
    }
  };

  const handleLoginSuccess = () => {
    console.log("Login successful, closing modal and rendering content...");

    // Trigger fade-out effect on the modal
    setModalFadeClass("fade-out");

    // Wait for the fade-out to complete (500ms) before actually closing the modal and reloading the page
    setTimeout(() => {
      setOpen(false); // Close the modal
      setModalView(""); // Clear the modal view
      window.location.reload(); // Refresh the page
    }, 500);
  };

  if (location.pathname === "/verify-email") {
    return <VerifyEmail />;
  }

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while user data is being fetched
  }

  return (
    <>
      <MapView userData={userData} />
      {user ? (
        <BurgerMenu
          logout={logout}
          setModalView={setModalView}
          setOpen={setOpen}
        />
      ) : (
        console.log("User is null, not rendering BurgerMenu")
      )}

      <WelcomeModal
        open={open}
        handleClose={() => setOpen(false)}
        onLoginSuccess={handleLoginSuccess}
        view={modalView}
        setView={setModalView}
        className={modalFadeClass} // Apply the fade-in or fade-out class to the modal
      />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
